@import "../../../scss/variables.scss";

.ticketInfo {
  padding: $space-ml;
  background-color: $ticketInfoBGGrey;
  display: flex;
  flex-direction: column;
  width: 100%;
  .ticketNumberContainer {
    display: flex;
    gap: $space-sm;
    justify-content: space-between;
    align-items: center;
    .ticketId {
      .ticketInfoHeader,
      .ticketInfoData {
        padding: 0;
        text-align: center;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-bold;
      }
    }
    .actionBtn {
      background-color: transparent;
      border: none;
      color: $textColorLight;
      cursor: pointer;
      font-size: 10px;
      font-style: normal;
      font-weight: $font-weight-semi-bold;
      // line-height: 26px; /* 260% */
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
      text-transform: uppercase;
      &.pending {
        color: $orangeColor;
      }
      &.inactive {
        color: $lightDanger;
      }
    }
  }
  .ticketStatus {
    color: $successColor;
    font-family: $font;
    font-size: 10px;
    font-style: normal;
    font-weight: $font-weight-semi-bold;
    // line-height: 26px; /* 260% */
    text-transform: uppercase;
    &.inactive {
      color: $lightDanger;
    }
    &.pending {
      color: $orangeColor;
    }
  }
  .ticketInfoContainer {
    margin-top: $space-md;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    gap: $space-sm;
    .ticketInfoCol {
      display: flex;
      flex-direction: column;
      .ticketInfoHeader {
        font-size: $font-size-small;
        text-transform: uppercase;
        font-weight: $font-weight-light;
        font-family: $font;
        color: $labelGrey;
        // line-height: 26px;
      }
      .ticketInfoData {
        font-size: $font-size-small;
        color: $textColorLight;
        font-family: $font;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        text-transform: uppercase;
        // line-height: 26px; /* 216.667% */
      }
    }
  }
}
