@import "../../../scss/variables.scss";

.responseMsg {
  width: 100%;
  //   height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $space-xl $space-lg;
  .btnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: $space-lg;
  }
  .iconContainer {
    color: $danger;
    font-size: $font-size-h2;
    &.success {
      color: $primaryColor;
    }
    &.warning {
      color: $warning;
    }
  }
  .title {
    text-align: center;
    font-family: $interFont;
  }
  .msg {
    margin-top: $space-lg;
    gap: $space-xs;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: $font-size-base;
    font-family: $interFont;
  }
}
