// @import '../../scss/variables.scss';
@import "../../../scss/variables.scss";

.myOrders {
  max-width: 100%;
  padding-top: 35px;
  padding-bottom: 82px;

  .myOrdersContainer {
    max-width: 100%;
    h2 {
      color: $headerBgColor;
      font-family: $font;
      font-size: $font-size-h2;
      font-style: normal;
      font-weight: $font-weight-bold;
      line-height: normal;
    }
    .searchBy {
      color: $primaryColor;
      font-family: $font;
      margin-top: $space-lg;
      font-size: $font-size-base;
      font-style: normal;
      font-weight: $font-weight-bold;
      line-height: normal;
    }
    .searchContainer {
      display: grid;
      grid-template-columns: repeat(2, minmax(130px, 1fr));
      grid-template-rows: 43px;
      gap: 10px;
      width: 100%;
      margin-top: 12px;
      input {
        padding: 12.5px 12px;
        border-radius: $border-radius-ml;
        border: 1px solid #eee;
        border: solid 1px $greyBoderColor;
        flex-shrink: 0;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: 23px; /* 164.286% */
        box-shadow: 0px 40px 60px 0px rgba(211, 209, 216, 0.5);
        ::placeholder {
          color: $textColorDark;
        }
      }
      input[type="text"]::placeholder {
        color: rgba(97, 102, 121, 0.5); /* Your desired color */
        color: $textColorDark; /* Your desired color */
      }
      input[type="date"]:not(:focus):not(:valid) {
        color: rgba(97, 102, 121); /* Placeholder color */
        opacity: 0.5; /* Ensures color applies */
      }

      input[type="date"]:focus,
      input[type="date"]:valid {
        color: $textColorDark; /* Value color */
        font-style: normal;
      }
    }
    .eventTypes {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 60px;
      margin-top: 19px;
      background-color: $lightBackground;
      border-radius: 0px 40px 0px 0px;
      &.pastSelected {
        background-color: $primaryColor;
      }
      button {
        width: 50%;
        display: inline-block;
        word-break: break-all;
        word-wrap: break-word;
        white-space: normal;
        padding: 5px;
        min-height: 60px;
        height: 100%;
        border-radius: 0px 40px 0px 0px;
        border: none;
        background: $lightBackground;
        &.active {
          background: $primaryColor;
        }
        span {
          display: inline-block;
          word-break: break-all;
          word-wrap: break-word;
          white-space: normal;
          width: 100%;
          color: $textColorLight;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
  @include largeBreakpoint {
    padding: 0;
    padding-top: 95px;
    padding-bottom: 82px;
  }
}
