@import "../../scss/variables.scss";

.titleAndMessage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .titleContainer,
  .messageContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .labelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        font-family: $font;
        font-size: $font-size-base;
        color: $textColorDark;
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid $greyBoderColor;
      border-radius: $border-radius-sm;
      font-family: $font;
      font-size: $font-size-base;

      &:focus {
        outline: none;
        border-color: $primaryColor;
      }

      &::placeholder {
        color: $lightGrey;
      }
    }

    textarea {
      resize: none;
      min-height: 200px;
    }
    .wordCount {
      width: 100%;
      text-align: right;
      font-family: $font;
      font-size: $font-size-small;
      color: $textColorDark;
    }
  }
}
