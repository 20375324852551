@import "../../scss/variables.scss";

.accountSettings {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 0;
  position: relative;
  background-color: $greyBackground;
  .dropdownBtn {
    padding: 0 $mobilePadding;
    gap: 11px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    fill: $headerBgColor;
    background-color: transparent;
    border: none;
    height: 56px;
    width: 100%;
    background: $lightBackground;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    .label {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-family: $font;
    }
    .icon {
      font-size: 21px;
    }
  }
  .sideBar {
    display: none;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 291px;
    transform: translateX(-291px);
    &.active {
      display: block;
      z-index: 1;
      animation: slideIn 0.2s ease-out forwards;
    }
    .navContainer {
      background-color: $lightBackground;
      height: 100%;
      // width: calc(100% - 59px);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      border-radius: 5px;
      min-height: 100%;
      border-radius: 0;
      nav {
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: repeat(4, 70px);
        .myBtn {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
          width: 100%;
          padding-left: 37px;
          height: 100%;
          border: none;
          background-color: transparent;
          .icon {
            font-size: 24px;
          }
          .label {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .navBtn {
          display: grid;
          grid-template-columns: 27px 1fr;
          grid-template-rows: 70px;
          padding-left: 59px;
          gap: 15px;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          fill: $headerBgColor;
          background-color: transparent;
          border: none;
          cursor: pointer;
          &.active {
            background-color: $primaryColor;
          }
          .label {
            text-align: left;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            font-family: $font;
          }
          .icon {
            font-size: 24px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 300px;
    flex: 1;
    padding-left: $mobilePadding;
    padding-right: $mobilePadding;
    // padding: 35px $mobilePadding 82px;
  }

  @include smallBreakpoint {
    padding-left: 0;
    padding-right: 0;
    .dropdownBtn {
      padding: 0 $tabletPadding;
    }
    .content {
      // padding: 35px $tabletPadding 82px;
      padding-left: $tabletPadding;
      padding-right: $tabletPadding;
    }
  }

  @include largeBreakpoint {
    padding: 0;
    display: grid;
    grid-template-columns: 250px 1fr;
    .dropdownBtn {
      display: none;
      .label {
      }
      .icon {
      }
    }
    .sideBar {
      position: static;
      display: block;
      width: 250px;
      transform: translateX(0);
      &.active {
        animation: none;
      }
      .navContainer {
        width: 100%;
        nav {
          .myBtn {
            padding-left: 35px;
            .icon {
            }
            .label {
            }
          }
          .navBtn {
            gap: 11px;
            padding-left: 54px;
            &.active {
            }
            .label {
            }
            .icon {
              font-size: 19px;
            }
          }
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 400px;
      flex: 1;
      padding-left: 74px;
      padding-right: $desktopPadding;
      width: 100%;
    }
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-291px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
