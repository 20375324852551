@import "../../../scss/variables.scss";

.contactOrganizer {
  padding: $mobilePadding;
  .closeBtnContainer {
    float: right;
    margin-bottom: $space-md;
    background-color: $modalBgColor;
    color: $textColorLight;
    border: none;
    cursor: pointer;
    height: $space-lg;
    width: $space-lg;
    border-radius: $border-radius-lg;
    font-size: $font-size-small;
  }
  .contactOrganizerTitle {
    font-size: $font-size-large;
    color: $textColorDark;
    margin-top: $space-xl;
  }
  .orderNumber {
    font-size: $font-size-base;
    color: $textColorDark;
    font-weight: $font-weight-light;
    margin: 0;
    padding: 0;
  }
  .messagePurposeContainer {
    margin: $space-md 0;
    display: flex;
    gap: $space-sm;
    align-items: center;
    justify-content: center;
    label {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      font-size: $font-size-base;
      color: $textColorDark;
    }
    .messagePurposeSelect {
      font-size: $font-size-base;
      color: $textColorDark;
      border: 1px solid $greyBoderColor;
      border-radius: $border-radius-sm;
      padding: $space-sm;
      width: 100%;
    }
  }
  .ticketSelectionContainer {
    margin-top: $space-md;
    margin-bottom: $space-md;
    .ticketSelectionTitle {
      font-size: $font-size-small;
      color: $textColorDark;
    }
    .ticketItem {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 0.5rem 0;
      .ticketInfo {
        margin-left: 0.5rem;
      }
    }
  }
  .buttonsConstainer {
    display: flex;
    gap: $space-md;
    align-items: center;
    justify-content: flex-start;
    margin-top: $space-lg;
  }
}
