@import "../../scss/variables.scss";
// @import "src/styles/mixin.scss";

.form {
  padding: $space-lg;
  margin: $space-lg auto;

  display: flex;
  flex-direction: column;
  gap: $space-md;

  border: 1px solid $thirdColor;
  border-radius: $border-radius-lg $border-radius-lg 0 0;

  h1 {
    font-weight: $font-weight-bold;
  }
}

.ticket-container {
  padding: $space-lg;

  display: flex;
  flex-direction: column;
  gap: $space-md;

  border: 1px solid $primaryColor;
  border-radius: $border-radius-lg;
  background-color: $primaryColor;
  color: white;
}

// @include text-input;
