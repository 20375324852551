@import "../../scss/variables.scss";

.checkoutRedirect {
  padding: $mobilePadding;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: $space-xl;
  .checkoutRedirectContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .iconContainer {
      margin-top: $space-lg;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      width: 100%;
      margin-top: $space-lg;
      font-size: $font-size-large;
      font-weight: $font-weight-bold;
      text-align: center;
      color: $textColorDark;
    }
    .orderId {
      width: 100%;
      margin-top: $space-sm;
      font-size: $font-size-base;
      font-weight: $font-weight-semi-bold;
      color: $textColorDark;
      text-align: center;
    }
    .orderMsg {
      width: 100%;
      margin-top: $space-lg;
      font-size: $font-size-base;
      color: $textColorDark;
      text-align: center;
      line-height: 2;
      padding: 0 $space-lg; // TODO: remove this on the large screen
    }
    .btnsContainer {
      width: 100%;
      max-width: 330px;
      margin-top: $space-lg;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: $space-md;
      .link {
        // margin-top: $space-lg;
        font-size: $font-size-base;
        color: $textColorDark;
        text-align: center;
        text-decoration: none;
        font-weight: $font-weight-semi-bold;
        border-bottom: 1px solid $textColorDark;
        padding-bottom: $space-xs;
        transition: all $transition-fast ease;
        &:hover {
          border-bottom: 2px solid $textColorDark;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.blackScreen {
  background-color: $bgColor;
  width: 100%;
  height: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
