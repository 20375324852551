@import "../../scss/variables.scss";

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-base;
  // padding: $space-md;
  width: $space-lg;
  height: $space-lg;
  border-radius: 50%;
  background-color: $modalBgColor;
  color: $iceWhite;
  border: none;
  &:hover {
    color: $textColorLight;
  }
  &.noBG {
    background-color: transparent;
    color: $textColorDark;
  }
}
