@import "../../../scss/variables.scss";
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $space-md;
  img {
    width: $space-xl;
    height: auto;
    position: absolute;
  }
}

.spinnerInner {
  width: $space-xxl;
  height: $space-xxl;
  border: $space-sm solid $iceWhite;
  border-top: $space-sm solid $primaryColor;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
