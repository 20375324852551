@import "../../scss/variables.scss";

.cartCard {
  border: 1px solid #d0d0d0;
  .cartCardContainer {
    .cartCardHeader {
      padding: $space-md $space-md $space-lg;
      background-color: #f2f2f2;
      .eventName {
        font-size: $font-size-large;
      }
    }
    .typeCardsContainer {
      padding: $space-lg $space-lg $space-xl;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: $space-md;
    }
    .cartCardFooter {
      background-color: #f2f2f2;

      .cartCardFooterContainer {
        display: grid;
        grid-template-columns: 3fr 2fr;
        padding: $space-lg;
        .couponContainer {
        }
      }
      .totalContainer {
        display: flex;
        flex-direction: column;
        gap: $space-sm;
        .subTotalContainer,
        .feesContainer,
        .ticketsAmountContainer {
          display: flex;
          gap: $space-sm;
          align-items: center;
          justify-content: flex-end;
          font-size: $font-size-small;
        }
        .subTotalContainer {
          font-size: $font-size-base;
          font-weight: $font-weight-semi-bold;
        }
      }
      // .garanteeContainer {
      //   padding: $space-lg;
      //   display: flex;
      //   align-items: center;

      //   gap: $space-sm;
      //   .garanteeText {
      //     font-size: $font-size-small;
      //   }
      //   .garanteeInfoButton {
      //     background-color: transparent;
      //     border: none;
      //     cursor: pointer;
      //     color: $primaryColor;
      //   }
      // }
    }
  }
}
