//  Fonts import
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

//  Colours
$primaryColor: #27aae1;
$thirdColor: #d7dce2;
$lightBackground: #d7dce2;
$greyBackground: #f5f5f5;
$bgColor: #ffffff;
$lightGrey: #6b6b6b;
$labelGrey: #c7c6c6;
$ticketInfoBGGrey: #232323;
$iceWhite: #f8f8f8;
$lightBorderGrey: #e6e6e6;
$greyTextColor: #c9c9c9;
$matteWhite: #fefffe;
$textColorDark: #000000;
$textColorGrey: #8f8b8b;
$textColorSemiDark: #3a2a2d;
$textColorDarkGrey: #475156;
$textColorLight: #ffffff;
$headerBgColor: #2a2c3a;
$modalBgColor: #2a2c3a;
$danger: #ed3237;
$lightDanger: #ff9d9d;
$warning: #ffc107;
$greyBoderColor: #ced4da;
$languagePickerBgColor: #f4f6f8;
$extraDarkBlue: #040f15;
$successColor: #38ff74;
$lightGreen: #9cffd0;
$orangeColor: #ffa663;

//  Fonts
$font: "Poppins", sans-serif;
$specialFont: "Montserrat", sans-serif;
$interFont: "Inter", sans-serif;
$font-icon: "Font Awesome 5 Free";

// Font Sizes
$font-size-small: 0.75rem; // 12px
$font-size-base: 1rem; // 16px
$font-size-large: 1.5rem; // 24px
$font-size-h1: 2rem; // 32px
$font-size-h2: 1.75rem; // 28px
// Font Weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
// Spacing (in rem)
$space-xs: 0.25rem; // 4px
$space-sm: 0.5rem; // 8px
$space-md: 1rem; // 16px
$space-ml: 1.5rem; // 24px
$space-lg: 2rem; // 32px
$space-xl: 4rem; // 64px
$space-xxl: 6rem; // 96px
// Border Radius
$border-radius-sm: 0.25rem; // 4px
$border-radius-md: 0.5rem; // 8px
$border-radius-ml: 0.75rem; // 12px
$border-radius-lg: 1rem; // 16px
$border-radius-xl: 2rem; // 32px
// Transitions
$transition-fast: 0.2s;
$transition-medium: 0.5s;
$transition-slow: 1s;

//  Spacing and Sizing
$mobilePadding: 2.8125rem;
$mobileRightHeaderPadding: 1.875rem;
$mobilePadding: 1.5625rem;
$mobileRightHeaderPadding: 1.5625rem;
$tabletPadding: 4.375rem;
$desktopPadding: 4.375rem;
$headerMobileHeight: 80px;
$headerHeight: 130px;
$maxContentWidth: 1294px;
$mobileFooterHeight: 920px;
$desktopFooterHeight: 440px;

//  Breakpoints
$smallBreakpoint: 576px;
$mediumBreakpoint: 768px;
$largeBreakpoint: 992px;
$xLargeBreakpoint: 1200px;

// Borders
$hoverBorder: 1px dotted $greyBoderColor;

//Mixins
// When it becomes tablet size
@mixin smallBreakpoint {
  @media (min-width: $smallBreakpoint) {
    padding-left: $tabletPadding;
    padding-right: $tabletPadding;
    @content;
  }
}

@mixin mediumBreakpoint {
  @media (min-width: $mediumBreakpoint) {
    @content;
  }
}

// When it becomes desktop size
@mixin largeBreakpoint {
  @media (min-width: $largeBreakpoint) {
    padding-left: $desktopPadding;
    padding-right: $desktopPadding;
    @content;
  }
}

// When it becomes xLarge size
@mixin xLargeBreakpoint {
  @media (min-width: $xLargeBreakpoint) {
    padding-left: $desktopPadding;
    padding-right: $desktopPadding;
    @content;
  }
}

// @include largeBreakpoint {content here}
